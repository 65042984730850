import {
  Button,
  ButtonProps
} from '@chakra-ui/react';


export interface CloseButtonProps {
  onClick: () => void;
  size?: ButtonProps['size'];
}

export default function CloseButton({ onClick, size = 'md' }: CloseButtonProps) {
  return(
    <Button
      colorScheme="red"
      variant="outline"
      size={size}
      marginRight={3}
      style={{
        width: 'auto',
        fontWeight: 300,
        borderRadius: '7px',
        boxShadow: 'none',
        padding: '1rem',
      }}
      onClick={onClick}
    >
      Fechar
    </Button>
  ); 
}

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Flex,
  Text,
  Box,
  Spinner,
  useToast,
  HStack,
} from '@chakra-ui/react';
import { Poppins } from 'next/font/google';
import SyncButton from '../buttons/sync_button';
import CloseButton from '../buttons/close_button';
import TreeList, { TreeListData, TreeListPops } from '../tree_list';
import { useEffect, useState } from 'react';
import { doctorOnboardingAPI, handleAPIReq } from '../../../api';
import { ShiftManagerGroup } from '@medsimples/doctor-onboarding-openapi-v2';
import * as E from 'fp-ts/Either';


const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

interface SyncProfessionalModalProps {
  isOpen: boolean;
  onSync: (ids: string[]) => void;
  onClose: () => void;
}

export default function SyncProfessionalModal({ isOpen, onSync, onClose}: SyncProfessionalModalProps) {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [groupTreeData, setGroupTreeData] = useState<TreeListData[]>([]);
  const toast = useToast();

  useEffect(() => {
    console.log('selectedKeys', selectedKeys);
  },[selectedKeys]);

  const onTreeCheck: TreeListPops['onCheck'] = (info) => setSelectedKeys([...info?.checkedKeys, ...info?.halfCheckedKeys]);

  const groupsToTree = (groups: ShiftManagerGroup[]): TreeListData[] => {
    return groups.map(g => {
      return {
        id: g.id,
        label: g.name,
        open: false,
        selected: false,
        parentId: g.parentGroupId,
      };
    });
  };

  useEffect(() => {
    if (!isOpen) return;
    setLoading(true);
    handleAPIReq(() => doctorOnboardingAPI.admin.listGroups()).then(res => {
      if (E.isLeft(res)) {
        toast({
          description: res.left,
          status: 'error',
          containerStyle: {
            fontFamily: poppins.style.fontFamily,
          },
        });
        return;
      }
      setGroupTreeData(groupsToTree(res.right.data));
    }).finally(() => {
      setLoading(false);
    });
  }, [isOpen]);

  return (
    <Modal size={'xl'} isOpen={isOpen} onClose={onClose} isCentered >
      <ModalOverlay />
      <ModalContent fontFamily={poppins.style.fontFamily}>
        <ModalHeader>
          <Box>
            <Text>Ativação</Text>
            <Text fontWeight={'normal'} fontSize={'xs'}>Escolha em quais unidades esse profissional poderá trabalhar:</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody minH={'50vh'}>
          {
            isLoading ?
              (<Flex justifyContent={'center'} alignItems={'center'} width={'100%'} flexDirection={'column'} margin={'25% 0'} rowGap={5}>
                <Spinner />
                <Text>Obtendo dados para a Ativação...</Text>
              </Flex>)
              : <TreeList checkStrictly={false} inputData={groupTreeData} onCheck={onTreeCheck} />
          }
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <SyncButton size={'md'} onClick={() => onSync(selectedKeys)}/>
            <CloseButton size={'md'} onClick={onClose} />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

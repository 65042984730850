import { Session } from '@ory/client';
import { useContext, createContext, ReactNode, useState, useEffect } from 'react';
import { oryAPI } from '../../api';
import { useRouter } from 'next/navigation';

interface AuthProviderValue {
  user: Session;
  logoutUrl: string;
  authenticate : () => Promise<void>;
}

const AuthContext = createContext({} as AuthProviderValue);

const AuthProvider = ({
  children
}: {
  children: ReactNode,
}) => {
  const [user, setUser] = useState<Session>(null);
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const router = useRouter();

  useEffect(() => {
    authenticate();
  }, []);

  const authenticate = () => oryAPI
    .toSession()
    .then(({ data }) => {
      setUser(data);
      oryAPI.createBrowserLogoutFlow().then(({ data }) => {
        setLogoutUrl(data.logout_url);
      });
    })
    .catch((err) => {
      console.error(err);
      router.push('/login');
    });

  return (
    <AuthContext.Provider value={{ user, logoutUrl, authenticate }}>
      { children }
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};

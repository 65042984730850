import { tokens } from '@/styles/tokens';
import { ProfessionalStatus, ProfessionalsStatusesCountData, WorkflowStatus } from '@medsimples/doctor-onboarding-openapi-v2';
import { Poppins } from 'next/font/google';

export type StatusLabel = keyof ProfessionalsStatusesCountData

export const statusName: Record<StatusLabel, string> = {
  'ALL': 'Todos',
  [ProfessionalStatus.REGISTERING]: 'Em cadastro',
  [WorkflowStatus.ACTION_PENDING]: 'Em recadastro',
  [ProfessionalStatus.WAITING_APPROVAL]: 'Em aprovação',
  [ProfessionalStatus.WAITING_COMPLIANCE]: 'Compliance',
  [ProfessionalStatus.WAITING_SYNCHRONIZATION]: 'Aprovado',
  [ProfessionalStatus.ENABLED]: 'Ativo',
  [ProfessionalStatus.DISABLED]: 'Reprovado'
};

export const providerLogoSizes = {
  amil: '15px',
  americas: '15px',
  open: '30px'
};

export const disabledFieldProps = {
  background: tokens.text.grayLighter.color,
  opacity: 1,
};

export const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

export const chakraThemeProps = {
  colorPrimary: '#00317E',
  colorPrimaryHover: '#024cbf',
  fontSize: 16,
  fontFamily: poppins.style.fontFamily,
  controlInteractiveSize: 20,
};

export type ApprovalType = 'approve' | 'return' | 'refuse'
